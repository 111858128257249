import { useEffect, useState, FC } from "react"
import { useGallery, useGlobalState } from "@app/contexts"
import { ProductCardImprovedWithCover } from "@app/features/products/components/product-card"
import {
  photoBookProductVisitsIncrement,
  canvasProductVisitsIncrement,
  photoSetProductVisitsIncrement,
  miniBookProductVisitsIncrement,
} from "@app/features/statistics"
import cn from "clsx"

import { useProductGroups } from "@app/contexts/product-groups"
import { useCoverRatio } from "@app/features/canvas-editor/hooks/use-cover-ratio"

import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./product-groups-list.module.scss"

const useVisibleProductGroups = (isOnlyAvailable, { disabledProductGroups }) => {
  const { groups } = useProductGroups()
  const [visibleGroups, setVisibleGroups] = useState(groups)

  useEffect(() => {
    let tmpVisibleGroups = []

    tmpVisibleGroups = groups.filter(
      (product) =>
        product &&
        disabledProductGroups.findIndex((disabledProduct) => {
          if (disabledProduct.handle === "PHOTOBOOK") {
            return disabledProduct.handle === product.handle.split("_")[0]
          }

          return disabledProduct.handle === product.handle
        }) < 0
    )

    setVisibleGroups(tmpVisibleGroups)
  }, [disabledProductGroups])

  return visibleGroups
}

type TProductsListProps = {
  onlyAvailable?: boolean
  position?: "modal" | "footer"
  onDiscountSuccess?: (discount: number) => void
}

const GROUPS_CLICK_EVENTS = {
  PHOTOBOOK: (galleryId: string) => {
    photoBookProductVisitsIncrement(galleryId).catch((error) => console.log(error))
  },
  CANVAS: (galleryId: string) => {
    canvasProductVisitsIncrement(galleryId).catch((error) => console.log(error))
  },
  PHOTOBOOK_CUSTOMER: (galleryId: string) => {
    photoBookProductVisitsIncrement(galleryId, true).catch((error) => console.log(error))
  },
  PHOTO_SET: (galleryId: string) => {
    photoSetProductVisitsIncrement(galleryId).catch((error) => console.log(error))
  },
  MINIBOOK: (galleryId: string) => {
    miniBookProductVisitsIncrement(galleryId)
  },
}

const ProductGroupsList: FC<TProductsListProps> = ({
  onlyAvailable = false,
  position = "modal",
  onDiscountSuccess,
}) => {
  const { gallery } = useGallery()
  const { disabledProductGroups } = gallery

  const coverRatio = useCoverRatio(gallery?.cover)
  const visibleProductGroups = useVisibleProductGroups(onlyAvailable, { disabledProductGroups })
  const productCount = visibleProductGroups.length
  const { imgFormat } = useGlobalState()

  useEffect(() => {
    // если хоть у одного товара есть скидка, меняем title секции
    if (visibleProductGroups.length > 0 && onDiscountSuccess) {
      for (let i = 0; i < visibleProductGroups.length; i++) {
        if (!!visibleProductGroups[i].price.discount) {
          onDiscountSuccess(visibleProductGroups[i].price.discount.percent)
          break
        }
      }
    }
  }, [visibleProductGroups, onDiscountSuccess])

  return (
    <>
      <div className={cn(styles["root"], styles[`count-${productCount}`])}>
        {visibleProductGroups.map((product, index) => (
          <div className={cn(styles["item"], { [styles["disabled"]]: product.disabled })} key={index}>
            {/*Вариант с новыми превью вместе с фото обложки галереи*/}
            <ProductCardImprovedWithCover
              {...product}
              onClick={GROUPS_CLICK_EVENTS[product.handle]}
              coverRatio={
                product.handle === "CANVAS" || (product.handle === "PHOTO_SET" && coverRatio) ? coverRatio : ""
              }
              galleryCoverSrc={`${gallery.cover?.m}${imgFormat}`}
              galleryName={gallery?.name}
              className={QA_CLASS_NAMES.productPreview[position][product.type.toLowerCase().replace(/-/g, "")]}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export { ProductGroupsList }
